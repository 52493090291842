<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            :range="true"
            defaultStart="-14d"
            defaultEnd="7d"
            label="작업기간"
            name="workDts"
            v-model="searchParam.workDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="작업계획별 작업자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template>
          <span v-if="getDate(props.row, col)">
              <font style="font-size:1.0em;font-weight:700;" :color="Number(getDate(props.row, col)) >= 5 ? '#C10015' : '#180dde'">
                {{ getDate(props.row, col) }}
              </font>
              <q-badge v-if="Number(getDate(props.row, col)) >= 7" color="negative" label="과중" class="blinking" />
          </span>
          <span v-else>
            <q-icon name="close" class="text-red"/> 
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        workDts: [],
        workMonths: [],
      },
      editable: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'workResultId' },
          { index: 0, colName: 'workResultWorkId' },
          { index: 1, colName: 'workResultWorkId' },
          { index: 2, colName: 'workResultWorkId' },
          // { index: 3, colName: 'workPlanWorkId' },
        ],
        columns: [
        ],
        data: [],
        // height: '320px',
      },
      listUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.listUrl = selectConfig.wod.workorder.result.workers.url;
      
      this.searchParam.workDts = ['2023-09-13', '2023-09-27']
      this.getList();
    },
    getList() {
      if (!this.searchParam.workDts || this.searchParam.workDts.length === 0) {
        this.grid.data = [];
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '작업기간을 선택하세요.', // 작업기간을 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        this.$http.url = this.listUrl;
        this.$http.param = this.searchParam;
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          if (this.grid.data && this.grid.data.length > 0) {
            this.$_.forEach(this.grid.data, _item => {
              if (_item.dailyList && _item.dailyList.length > 0) {
                this.$_.forEach(_item.dailyList, __item => {
                  _item[__item.workDate] = __item.workTime
                })
              }
            })
          }
          this.setWorkerColumns();
        },);
      }
    },
    setWorkerColumns() {
      let _columns = [
        {
          name: 'workResultName',
          field: 'workResultName',
          label: '정비오더명',
          style: 'width:150px',
          align: 'left',
          sortable: false,
          type: 'link'
        },
        {
          name: 'sortOrder',
          field: 'sortOrder',
          label: '항목',
          align: 'center',
          style: 'width:60px',
          sortable: false,
        },
        {
          name: 'workResultWorkName',
          field: 'workResultWorkName',
          label: '작업명',
          style: 'width:150px',
          align: 'left',
          sortable: false,
        },
        {
          name: 'workDtsStr',
          field: 'workDtsStr',
          label: '작업기간',
          align: 'center',
          style: 'width:120px',
          sortable: false,
        },
        {
          name: 'workerName',
          field: 'workerName',
          label: '작업자',
          style: 'width:120px',
          align: 'center',
          sortable: false,
        },
      ]

      // if (this.grid.data && this.grid.data.length > 0) {
        // let startDt = this.$_.map(this.grid.data, 'workStartDt').reduce((prev,curr) => {
        //   // 이전것과 비교해 더 큰 것을 리턴
        //     return new Date(prev).getTime() <= new Date(curr).getTime() ? curr : prev;
        // })

        // let endDt = this.$_.map(this.grid.data, 'workEndDt').reduce((prev,curr) => {
        //   // 이전것과 비교해 더 큰 것을 리턴
        //     return new Date(prev).getTime() <= new Date(curr).getTime() ? curr : prev;
        // })

        let dayCnt = this.$comm.getDatediff(this.$comm.moment(this.searchParam.workDts[0]), this.$comm.moment(this.searchParam.workDts[1]))

        for (let i=0; i<=dayCnt; i++) {
          let date = this.$comm.getCalculatedDate(this.$comm.moment(this.searchParam.workDts[0]), i+'d', 'YYYY-MM-DD')
          let month = date.substring(5);
          
          _columns.push({
            name: date,
            field: date,
            label: month,
            style: 'width:50px',
            align: 'center',
            type: 'custom',
            sortable: false,
          },)
        }
      // }
      this.grid.columns = _columns;
    },
    getDate(row, col) {
      if (row.workStartDt <= col.name && col.name <= row.workEndDt) {
        return row[col.name] ? row[col.name] : '0'
      } else {
        return ''
      }
    },
    linkClick(row) {
      this.popupOptions.title = '정비오더 상세';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: row ? row.woRequestId : '',
        workPlanFlag: true,
      };
      this.popupOptions.target = () => import(`${'./workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>